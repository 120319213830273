* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.container_fluid {
    position: relative;
    width: 100%;
    height: 100vh;
    margin: 0%;
    padding: 0%;
    background-color: #0E3386;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginBackground {
    background-color: white;
    height: 550px;
    width: 400px;
    border-radius: 20px;
    box-shadow: 0px 2px 2px lightgray;
}

.loginHeader {
    height: 33.33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.LoginContent {
    height: 33.33%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.LoginContent div h2 {
    font-weight: 800;
}

.UserInput {
    width: 60%;
    height: 33%;
    font-size: 14px;
    margin-top: 10px;
}

.UserInput p {
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
}

.UserInput input {
    height: 70%;
    width: 100%;
    border: 2px solid 0E3386;
    margin-top: 2px;
    padding-left: 5px;
    font-family: 'Source Sans Pro', sans-serif;
}

.forgotPassword {
    font-size: 12px;
    color: #0A76DD;
    padding: 5px;
}

.loginFooter {
    margin-top: 5px;
    height: 33.33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-evenly; */
    align-content: center;
}

.LoginButton {
    width: 60%;
    height: 30%;
    padding: 2px;
}

.LoginButton input {
    height: 100%;
    width: 100%;
    background-color: #0E3386;
    border-radius: 12px;
    border-color: white;
    color: white;
    font-size: 18px;
    font-weight: 600;

}

.loginSignUp {
    font-size: 14px;
    padding: 5px;
}