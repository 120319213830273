/* for universal remove the margin and padding */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Main container */
.container {
    margin: 3% 5%;
    min-height: 100vh;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
}

/* Profile title */
.title {
    min-height: 10vh;
    background-color: #0E3386;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px 10px 0 0;
    padding: 0px 20px;
}

.title p {
    margin: 0;
    font-size: 21px;
    font-weight: 400;
    font-family: 'Source Sans Pro', sans-serif;
}

/* Profile Fields */
.fields {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 20px;
    gap: 10px;
}

.field {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.field label {
    font-size: 16px;
    font-weight: 700;
    font-family: 'Source Sans Pro', sans-serif;
}

.field input {
    font-size: 14px;
    font-family: 'Souce Sans Pro', sans-serif;
    padding: 0 8px;
    min-height: 35px;
    border: 0.5px solid #D9D9D9;
    border-radius: 5px;
}

.field textarea {
    font-size: 14px;
    font-family: 'Souce Sans Pro', sans-serif;
    padding: 0 8px;
    border: 0.5px solid #D9D9D9;
    border-radius: 5px;
}

/* button */
.editButton {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 10px;
}