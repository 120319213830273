/* import font */
/* @font-face {
    font-family: 'Source Sans Pro';
    src: url('/public/fonts/SourceSansPro-Regular.otf') format('otf');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('/public/fonts/SourceSansPro-Bold.otf') format('otf');
    font-weight: 700;
    font-style: normal;
} */

/* css for card component */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.cardBox {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.cardBox .card1 {
    position: relative;
    background-color: #CCD4FF;
    padding: 30px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: 1px solid #0E3386;
    margin: 10px;
    box-shadow: 0px 3px 10px lightgray;
}

.cardBox .card1 .numbers {
    position: relative;
    font-weight: 500;
    font-size: 40px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #0E3386;
}

.cardBox .card1 .CardName {
    color: black;
    font-size: 18px;
    margin-top: 5px;
    font-family: 'Source Sans Pro', sans-serif;
}

.cardBox .card1 .iconBox {
    font-size: 56px;
    color: #0E3386;
    font-family: 'Source Sans Pro', sans-serif;
}

.cardBox .card1:hover {
    background-color: #0E3386;
}

.cardBox .card1:hover .numbers,
.cardBox .card1:hover .CardName,
.cardBox .card1:hover .iconBox {
    color: white;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .cardBox {
        grid-template-columns: 1fr;
        /* Change to a single column for mobile */
    }
}