* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    position: relative;
    width: calc(100%-20px);
    max-height: 350px;
    background-color: white;
    box-shadow: 0px 3px 10px lightgray;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}

.cardheaderrightview {
    display: flex;
    width: 12%;
    align-items: center;
    justify-content: flex-end;
}

.cardheaderrightview i {
    color: chartreuse;
    font-size: 24px;
    margin: 20px 0 10px 20px;
    padding: 0px 20px 0px 0px;
}

.cardheaderrightview i:hover {
    cursor: pointer;
}

.header {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding-top: 20px;
    padding-left: 20px;
    border-bottom: 1px solid #0E3386;
}

h2 {
    padding: 0;
    margin: 0;
    font-weight: 400;
}

.imagePreview {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.imageContainer {
    height: 220px;
    max-width: 200px;
    /* background-color: aqua; */
}

.image {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    height: 190px;
    max-width: 200px;
    /* background-color: #0E3386; */
    overflow: hidden;
}

.image img {
    object-fit: cover;
    /* or contain, depending on your preference */
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.action {
    display: flex;
    justify-content: center;
    align-items: center;
}

.deleteIcon {
    padding: 6px 8px;
}

.deleteIcon:hover {
    cursor: pointer;
}

.editIcon:hover {
    cursor: pointer;
}