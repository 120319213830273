.Navbar {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  font-size: 40px;
  /* cursor: pointer; */
  background-color: white;
  box-shadow: 0px 3px 10px lightgray;
  /* border: 1px solid black; */
}

.toggle {
  position: relative;
  margin-top: 0px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  cursor: pointer;
}

.search {
  position: relative;
  width: 400px;
  margin: 0px 10px;
  display: flex;
}

.search label {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.search label input {
  width: 100%;
  height: 40px;
  font-size: 15px;
  border-radius: 20px;
  padding: 5px 20px;
  padding-left: 35px;
  outline: none;
  background-color: #f0f5ff;
  border: 1px solid black;
}

.search label i {
  position: absolute;
  top: 4px;
  left: 10px;
  font-size: 1.5rem;
  width: 20%;
  height: 40px;
}

.user img {
  cursor: pointer;
  margin: 0px 2px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  border-radius: 25%;
}

.main {
  position: absolute;
  width: calc(100% - 250px);
  left: 250px;
  min-height: 100vh;
  overflow-x: hidden;
  box-sizing: border-box;
  transition: 0.3s;
  background-color: #e3e6e6;
}

.main.Sidebarclose {
  width: calc(100% - 100px);
  left: 100px;
  transition: 0.3s;
}

.no-underline {
  text-decoration: none !important;
  text-align: center;
  margin: 0 2px 0 0;
}
