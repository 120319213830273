* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    overflow-x: hidden;
}

.container-fluid {
    position: relative;
    width: 100%;
    margin: 0%;
    padding: 0%;
}

.sidebar {
    position: fixed;
    width: 250px;
    height: 100%;
    background: #0E3386;
    border-left: 10px #0E3386;
    transition: 0.3s;
    overflow: scroll;
}


.sidebar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.sidebar.Sidebarclose {
    width: 80px;
    transition: 0.3s;
}

.sidebar.active {
    width: 80px;
}

.sidebar ul {
    position: absolute;
    margin-top: 0px;
    padding-left: 0px;
    width: 100%;
}

.sidebar ul li {
    position: relative;
    padding-left: 10px;
    width: 100%;
    list-style: none;
}

.sidebar ul li a:hover {
    background-color: #E3E6E6;
    color: #0E3386;
}

.sidebar ul li:nth-child(1) {
    margin-bottom: 40px;
    pointer-events: none;
}

.sidebar ul li a {
    position: relative;
    display: block;
    width: 100%;
    display: flex;
    text-decoration: none;
    color: white;
}

.sidebar ul li a .icon {
    position: relative;
    display: block;
    min-width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}

.sidebar ul li .Sidebartext {
    position: relative;
    display: block;
    padding: 0px 10px;
    height: 60px;
    line-height: 60px;
    text-align: start;
    white-space: nowrap;
}

.active {
    background-color: #E3E6E6;
}

.active .Sidebartext {
    color: #0E3386;
}

.active .icon {
    color: #0E3386;
}