@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&family=Source+Sans+3:wght@300&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.CustomerDetails {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr;

}

.CustomerDetails .CustomerList {
    position: relative;
    width: 100%;
    /* display: grid; */
    max-height: 350px;
    background-color: white;
    padding: 20px;
    box-shadow: 0px 3px 10px lightgray;
    border-radius: 20px;
}

.CustomerDetails .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.cardHeader h2 {
    font-weight: 400;
    font-family: 'Source Sans Pro', sans-serif;
    color: #0E3386;
}

/* For view All Button */
.cardHeader .btn {
    position: relative;
    padding: 5px 10px;
    background-color: #0E3386;
    color: white;
    border-radius: 6px;
    text-decoration: none;
    font-family: 'Source Sans Pro', sans-serif;
}

.CustomerDetails table {
    width: 100%;
    border-collapse: collapse;
}

.CustomerDetails table thead td {
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif;
}

.CustomerDetails .CustomerList table tr {
    color: black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

}

.CustomerDetails .CustomerList table tr:last-child {
    border-bottom: none;

}

.CustomerDetails .CustomerList table tbody tr:hover {
    background-color: #0E3386;
    color: white;
}

.status.Active {
    padding: 2px 4px;
    background-color: chartreuse;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif;
}

.status.Inactive {
    padding: 2px 4px;
    background-color: tomato;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif;
}

.CustomerDetails .CustomerList table tr td {
    padding: 10px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
}

.CustomerDetails .CustomerList table tbody tr td i {
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
}

.detailIcon:hover {
    cursor: pointer;
}

.editIcon:hover {
    cursor: pointer;
}

.deleteIcon:hover {
    cursor: pointer;
}

.hover-zoom {
    transition: transform 0.3s;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hover-zoom:hover {
    transform: scale(1.2);
    z-index: 9999;
}