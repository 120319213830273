* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.CustomerDetails {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr;
}

.CustomerDetails .CustomerList {
    position: relative;
    width: 100%;
    /* display: grid; */
    min-height: 350px;
    background-color: white;
    padding: 0px;
    box-shadow: 0px 3px 10px lightgray;
    border-radius: 20px;
}

.CustomerDetails .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.cardHeader h2 {
    font-weight: 400;
    color: #0E3386;
    padding: 20px 0px 0px 20px;
}

.cardHeader .btn {
    position: relative;
    padding: 5px 10px;
    background-color: #0E3386;
    color: white;
    border-radius: 6px;
    text-decoration: none;
}

.CardHeaderRightView {
    display: flex;
    justify-content: space-between;
    width: 12%;
    align-items: center;
    padding: 20px 20px 0px 0px;
}

.CardHeaderRightView i {
    color: chartreuse;
    font-size: 24px;
}

.file-add {
    color: chartreuse;
    font-size: 24px;
}

.file-add:hover {
    cursor: pointer;
}

.CardHeaderRightView i:hover {
    cursor: pointer;
}

.CustomerDetails table {
    width: 100%;
    border-collapse: collapse;

}

.CustomerDetails table thead td {
    font-weight: 600;
}

.CustomerDetails .CustomerList table tr {
    color: black;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

}

.CustomerDetails .CustomerList table tr:last-child {
    border-bottom: none;

}

.CustomerDetails .CustomerList table tbody tr:hover {
    background-color: #0E3386;
    color: white;
}

.status.Active {
    padding: 2px 4px;
    background-color: chartreuse;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
}

.status.Inactive {
    padding: 2px 4px;
    background-color: tomato;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
}

.CustomerDetails .CustomerList table tr td {
    padding: 10px;
}

.CustomerDetails .CustomerList table tbody tr td i {
    font-size: 20px;
}


.AddJewellerHeader {
    background-color: #0E3386;
    color: white;
}

.AddJewellerInput {
    border: 1px solid;
    border-color: #0E3386;
}

thead {
    position: sticky;
    top: 0px;
    background: #0E3386;
}

thead tr td {
    color: #0E3386;
}

tbody {
    min-height: 240px;
    overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.CustomerDetails .CustomerList::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    /* for IE and Edge */
    scrollbar-width: none;
    /* for Firefox */

}

tbody::-webkit-scrollbar {
    display: none;
}

.detailIcon:hover {
    cursor: pointer;
}

.editIcon:hover {
    cursor: pointer;
}

.deleteIcon:hover {
    cursor: pointer;
}

.hover-zoom {
    transition: transform 0.3s;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hover-zoom:hover {
    position: absolute;
    transform: scale(1.2);
    z-index: 9999;
    top: 0;
    left: 0;
    /* width: auto;
    height: 100%;
    max-width: 80vw;
    max-height: 80vh; */
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}